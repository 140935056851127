@media screen and (max-width: 1199px) {
  #PageWrapper .insitutionalPortalMainCol {
    margin-top: 24px !important;
    margin-bottom: 24px;
    padding-right: 24px !important;
    padding-left: 24px !important;
  }
  .insitutionalPortalMainCol .insitutionalPortalDescriptionCol {
    margin: 0 0 1rem !important;
  }

  .insitutionalPortalMainHeading {
    margin-bottom: 24px;
  }

  .institutionalContactDiv {
    display: flex !important;
    justify-content: flex-start !important;
    gap: 16px;
  }

  .institutionalContactDiv .insitutionalContactText,
  .institutionalContactDiv .institutionalContactIcon {
    width: auto;
    flex: initial;
    padding: 0;
  }

  .insitutionalContactText h3 {
    font-size: 20px;
    line-height: 28px;
  }

  .insitutionalContactTextEmail {
    max-width: 70% !important;
    overflow-wrap: break-word;
  }

  .insitutionalContactTextEmail p {
  }

  .insitutionalContactText p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 8px;
  }

  .contactPageFooter .copyrightSection {
    display: none !important;
  }

  .contactPageFooter .footerContainer {
    background: transparent;
    padding: 0;
  }
}

/* Tablet */
@media screen and (min-width: 700px) and (max-width: 1199px) {
  .footerMainCol {
    max-width: 100%;
    width: 100%;
    margin: 1rem 0 1rem !important;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    grid-row-gap: 2rem;
  }

  .footerMainCol > .row > .col-md-auto {
    margin-top: 0 !important;
  }
}
