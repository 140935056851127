.image-gallery-thumbnail {
  margin-top: 1vmin;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 4rem !important;
  object-fit: cover !important;
}

.image-gallery-left-nav {
  padding-left: 0 !important;
}

.image-gallery-right-nav {
  padding-right: 0 !important;
}

.image-gallery-left-nav,
.image-gallery-left-nav {
  outline: none !important;
  padding: 0 !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  color: #424242 !important;
  background: #fff !important;
}

@media (max-width: 480px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 39px !important;
  }
}

@media (min-width: 481px) and (max-width: 1210px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 75px !important;
  }
}

@media screen and (min-width: 1210px) {
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    height: 6vmin !important;
    width: 5vmin !important;
  }
}

.image-gallery-left-nav .image-gallery-svg {
  border-radius: 0 1vmin 1vmin 0 !important;
}

.image-gallery-right-nav .image-gallery-svg {
  border-radius: 1vmin 0 0 1vmin !important;
}

.image-gallery-icon {
  filter: unset !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border-width: 3px !important;
  border-color: #82d124 !important;
}

.image-gallery-thumbnail:hover {
  border-color: #72b81f !important;
}

@media (hover: hover) and (pointer: fine) {
  .image-gallery-icon:hover .image-gallery-svg {
    transform: scale(1) !important;
    color: #72b81f !important;
    transition: all 0.2s !important;
  }
}

/* Phones */
@media (max-width: 399px) {

  .image-gallery-wrapper{
    margin: 0px 0px 0px -13px!important;
  }
}


@media (max-width: 600px) {
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    padding-top: 0;
  }

  .image-gallery-slides .image-gallery-slide .image-gallery-image {
    height: 30vh !important;
    object-fit: cover !important;
  }

  .fullscreen .image-gallery-image {
    height: calc(100vh - 80px) !important;
    object-fit: contain !important;
  }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 1210px) {
  .image-gallery-thumbnail .image-gallery-thumbnail-image {
    padding-top: 0;
  }
  .image-gallery-slides .image-gallery-slide .image-gallery-image {
    height: 20rem !important;
    object-fit: cover !important;
  }

  .fullscreen .image-gallery-image {
    height: calc(100vh - 80px) !important;
    object-fit: contain !important;
  }
}

/* Desktop only */
@media screen and (min-width: 1200px) {
  .image-gallery-image {
    height: 24rem !important;
    object-fit: cover !important;
    padding-top: 0 !important;
  }

  .fullscreen .image-gallery-image {
    height: calc(100vh - 80px) !important;
    object-fit: contain !important;
  }

  .image-gallery-slide {
    background-color: #222;
  }
}
