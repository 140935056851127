@import '~react-image-gallery/styles/css/image-gallery.css';
body {
  margin: 0;
  font-family: 'Steradian', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Steradian';
  src:
    local('Steradian'),
    url('../fonts/Steradian/steradian-black.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'Steradian';
  src:
    local('Steradian'),
    url('../fonts/Steradian/steradian-bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Steradian';
  src:
    local('Steradian'),
    url('../fonts/Steradian/steradian-medium.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Steradian';
  src:
    local('Steradian'),
    url('../fonts/Steradian/steradian-regular.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'Steradian';
  src:
    local('Steradian'),
    url('../fonts/Steradian/steradian-light.otf') format('opentype');
  font-weight: 400;
}
@font-face {
  font-family: 'Steradian';
  src:
    local('Steradian'),
    url('../fonts/Steradian/steradian-thin.otf') format('opentype');
  font-weight: 300;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css');
