.App {
  min-height: 100vh;
  background-color: #fbfbff;
}

:root,
[data-bs-theme='light'] {
  --bs-border-color: #cdcdcd !important;
}

.container-header {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.loader-background {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #8d8d8d71;
  display: flex;
  align-items: center;
}

footer {
  border-top: 1px solid #d5d0d0;
  position: absolute;
  bottom: 0px;
  min-height: 50px;
  background-color: #fbfbff;
  font-size: smaller;
  font-family: 'Lato', sans-serif;
  padding-left: 15px;
  padding-right: 15px;
}

.footer-link {
  color: #6468c8;
  cursor: pointer;
}

.footer-link:hover {
  font-weight: bold;
  text-decoration: none;
  color: #6468c8;
}

.footer-row {
  min-height: 50px;
}

.cursor-pointer {
  cursor: pointer;
  max-height: 20px;
}

.h-100.container-fluid {
  min-height: 50px;
}
@media screen and(max-width:900px) {
  .h-100.container-fluid {
    min-height: none;
  }
}

.header-row {
  min-height: 72px;
}

header {
  font-family: 'Lato', sans-serif;
  background-color: #6468c8;
  color: #fbfbff;
  min-height: 72px;
  box-shadow: 0px 3px 6px #6468c859;
}

.header-search {
  min-height: 44px;
  border-top-left-radius: 22px !important;
  border-bottom-left-radius: 22px !important;
  padding-left: 30px !important;
}

.header-search-button {
  background-color: #f3711a !important;
  min-height: 45px;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}

.trilhas-mobile-title {
  margin-top: auto !important;
  margin-bottom: auto !important;
  font-weight: lighter;
  max-width: 180px;
}

.home-icon-mobile {
  max-height: 12px;
}

span {
  display: inline-block;
}

.header-icons {
  max-width: 130px !important;
}

p {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
span {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
h1 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
h2 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
h3 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
h4 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
h5 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
h6 {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
