.awssld {
  --slider-height-percentage: 60% !important;
  --slider-transition-duration: 200ms !important;
  --organic-arrow-thickness: 5px !important;
  --organic-arrow-border-radius: 100px !important;
  --organic-arrow-height: 20px !important;
  --organic-arrow-color: #72b81f !important;
  --control-button-width: 5% !important;
  --control-button-height: 11% !important;
  --control-button-background: #fff !important;
  --control-button-opacity: 1 !important;
  --control-bullet-color: #72b81f !important;
  --control-bullet-active-color: #198754 !important;
  --loader-bar-color: #72b81f;
  --loader-bar-height: 0px !important;
}
